.u-uppercase {
  text-transform: uppercase !important; }

.u-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

* {
  box-sizing: border-box; }

body {
  font-family: "PT Sans", sans-serif; }

html {
  touch-action: manipulation;
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font variant */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px; }

label[for] {
  cursor: pointer; }

h1,
h2 {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  line-height: 41px; }

p {
  color: #222;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  line-height: 30px; }

p + p {
  margin-top: 30px; }

@keyframes scrollwheel {
  0% {
    transform: translate3d(-50%, 0, 0); }
  50% {
    transform: translate3d(-50%, 10px, 0);
    opacity: 1; }
  60% {
    transform: translate3d(-50%, 10px, 0);
    opacity: 0; }
  100% {
    transform: translate3d(-50%, 10px, 0);
    opacity: 0; } }

.a-mouse-scroll-down {
  color: #fff;
  position: relative;
  opacity: 0.7;
  width: 20px;
  height: 40px;
  border: 2px solid;
  border-radius: 10px; }
  .a-mouse-scroll-down::before {
    content: "";
    display: block;
    box-shadow: inset 0 0 0 2px;
    background: transparent;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    border-radius: 50%;
    width: 4px;
    height: 4px;
    animation: 2400ms scrollwheel infinite; }

.a-section {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.a-section__wrapper {
  height: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  max-width: 1200px;
  width: 80%;
  margin: 0 auto; }

.a-section__name {
  font-family: "Avenir Next", sans-serif;
  font-weight: 400;
  text-transform: uppercase; }

.track h2,
.track p {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition-property: transform, opacity;
  transition-duration: 500ms;
  transition-delay: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.track p {
  transition-delay: 550ms;
  transform: translate3d(0, 40px, 0); }

.track.is-visible h2,
.track.is-visible p {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.a-title {
  font-size: 42px;
  line-height: 55px; }

.a-title--small {
  display: flex;
  align-items: center;
  font-size: 32px;
  line-height: 52px;
  color: inherit; }
  .a-title--small .fa-chevron-left,
  .a-title--small .fa-chevron-right {
    color: #27e76e;
    transform: translateY(4px); }
  .a-title--small .fa-chevron-left {
    margin-right: 10px; }
  .a-title--small .fa-chevron-right {
    margin-left: 10px; }

.a-hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent !important;
  border: 0;
  margin: 0;
  margin-top: 5px;
  overflow: visible; }
  .a-hamburger:hover {
    opacity: 0.7; }
  .a-hamburger:focus, .a-hamburger:active {
    outline: none; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #2c2d32;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 6px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 12px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 6px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -12px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

.m-clients__list {
  display: flex;
  flex-wrap: wrap; }
  .m-clients__list figure {
    width: 50%;
    padding: 20px;
    margin: 0;
    display: flex;
    place-content: center; }
    .m-clients__list figure img {
      width: 100%;
      max-height: 150px;
      filter: grayscale(100%);
      transition: filter 225ms cubic-bezier(0, 0, 0.5, 1.43); }
    .m-clients__list figure:hover img {
      filter: none; }

@media only screen and (min-width: 575px) {
  .m-clients__list figure {
    width: 33.33%; } }

@media only screen and (min-width: 768px) {
  .m-clients__list figure {
    width: 25%; } }

.m-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #f7f7f7;
  z-index: 99999; }
  .m-navigation .m-navigation__wrapper {
    background-color: #fff;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    z-index: 3;
    padding: 0 20px; }
  .m-navigation .m-navigation__items {
    background-color: #2c2d32;
    width: 100%;
    position: absolute;
    left: 0;
    top: 15px;
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    transition: top 0.3s cubic-bezier(0, 0, 0.5, 1.43); }
  .m-navigation .m-navigation__item {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase; }
  .m-navigation.js-open .m-navigation__items {
    top: 60px; }

@media only screen and (min-width: 575px) {
  .m-navigation .a-hamburger {
    display: none; }
  .m-navigation .m-navigation__items {
    position: relative;
    z-index: 4;
    justify-content: flex-end;
    background-color: transparent;
    align-items: center;
    top: auto; }
  .m-navigation .m-navigation__item {
    color: rgba(44, 45, 50, 0.64); }
  .m-navigation .m-navigation__item + .m-navigation__item {
    display: inline-block;
    margin-left: 30px; }
  .m-navigation.js-open .m-navigation__items {
    top: auto; } }

.m-section-with-background {
  flex-direction: row;
  text-align: center;
  background: #fff url("../../../images/header-bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  .m-section-with-background h1 {
    color: #2c2d32;
    font-size: 48px;
    line-height: 52px;
    font-weight: 100;
    max-width: 500px;
    margin: 0 auto 30px; }
  .m-section-with-background strong {
    font-weight: 600; }
  .m-section-with-background p {
    max-width: 672px;
    margin: 0 auto; }

.m-section-about .a-mouse-scroll-down {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #2c2d32; }

.m-section-services {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .m-section-services .m-section-services__content {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(44, 45, 50, 0.8);
    height: 100vh; }
    .m-section-services .m-section-services__content h3,
    .m-section-services .m-section-services__content p {
      color: #fff; }
    .m-section-services .m-section-services__content::after {
      content: "";
      background: url("../../../images/contact.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%; }
  .m-section-services .m-section-services__content__inner {
    padding: 0 30px; }
    .m-section-services .m-section-services__content__inner .fa-chevron-left,
    .m-section-services .m-section-services__content__inner .fa-chevron-right {
      display: none; }
  .m-section-services .m-section-services__content__inner + .fa-chevron-down {
    position: absolute;
    bottom: 10px;
    color: #27e76e;
    left: 50%;
    transform: translateX(-50%); }
  .m-section-services .m-section-services__list__header {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px; }
    .m-section-services .m-section-services__list__header h3 {
      color: #2c2d32; }
    .m-section-services .m-section-services__list__header p {
      max-width: 80%; }
  .m-section-services .m-section-services__list {
    display: flex;
    flex-wrap: wrap; }
  .m-section-services .m-section-services__list__item {
    width: 50%;
    padding: 20px; }
    .m-section-services .m-section-services__list__item img {
      display: block;
      margin: 0 auto;
      vertical-align: top;
      height: 70px; }
    .m-section-services .m-section-services__list__item h3 {
      text-align: center; }
    .m-section-services .m-section-services__list__item p {
      font-size: 16px; }

.m-section-clients p {
  text-align: center; }

.m-section-clients .a-title--small {
  display: block;
  text-align: center; }

.m-section-key-values {
  min-height: 40vh;
  margin: 70px 0 calc(20vh + 70px) 0; }

.m-section-contact {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch; }
  .m-section-contact .m-section-contact__content,
  .m-section-contact .m-section-contact__info {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #2c2d32; }
    .m-section-contact .m-section-contact__content .a-title--small,
    .m-section-contact .m-section-contact__content p,
    .m-section-contact .m-section-contact__info .a-title--small,
    .m-section-contact .m-section-contact__info p {
      color: #fff; }
  .m-section-contact .m-section-contact__content {
    position: relative;
    background: rgba(44, 45, 50, 0.8);
    height: 100vh; }
    .m-section-contact .m-section-contact__content::after {
      content: "";
      background: url("../../../images/services.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%; }
  .m-section-contact .m-section-contact__content__inner {
    max-width: 50%; }
    .m-section-contact .m-section-contact__content__inner .fa-chevron-left,
    .m-section-contact .m-section-contact__content__inner .fa-chevron-right {
      display: none; }
  .m-section-contact .m-section-contact__content__inner + .fa-chevron-down {
    position: absolute;
    bottom: 10px;
    color: #27e76e;
    left: 50%;
    transform: translateX(-50%); }
  .m-section-contact .m-contact__block {
    display: flex;
    align-items: center;
    padding: 20px; }
    .m-section-contact .m-contact__block i,
    .m-section-contact .m-contact__block svg {
      color: #fff;
      margin-right: 20px; }
    .m-section-contact .m-contact__block a,
    .m-section-contact .m-contact__block span {
      color: #fff;
      text-decoration: none; }
  .m-section-contact .m-contact__block + .m-contact__block {
    border-top: 1px solid #454545; }

@media only screen and (min-width: 991px) {
  .m-section-about {
    background-size: auto; }
  .m-section-services {
    flex-direction: row; }
    .m-section-services .m-section-services__content {
      width: 50%;
      height: auto; }
    .m-section-services .m-section-services__content__inner {
      max-width: 50%; }
      .m-section-services .m-section-services__content__inner .fa-chevron-left,
      .m-section-services .m-section-services__content__inner .fa-chevron-right {
        display: inline-block; }
    .m-section-services .m-section-services__content__inner + .fa-chevron-down {
      display: none; }
  .m-section-contact {
    flex-direction: row; }
    .m-section-contact .m-section-contact__content,
    .m-section-contact .m-section-contact__info {
      width: 50%; }
    .m-section-contact .m-section-contact__content {
      height: auto; }
    .m-section-contact .m-section-contact__content__inner .fa-chevron-left,
    .m-section-contact .m-section-contact__content__inner .fa-chevron-right {
      display: inline-block; }
    .m-section-contact .m-section-contact__content__inner + .fa-chevron-down {
      display: none; } }

@media only screen and (max-width: 768px) {
  .m-section-services {
    flex-direction: column; }
    .m-section-services .m-section-services__content {
      width: 100%;
      height: auto;
      padding: 50px 0; }
    .m-section-services .m-section-services__content__inner {
      max-width: 90%; }
      .m-section-services .m-section-services__content__inner .fa-chevron-left,
      .m-section-services .m-section-services__content__inner .fa-chevron-right {
        display: inline-block; }
    .m-section-services .m-section-services__content__inner + .fa-chevron-down {
      display: none; }
    .m-section-services .m-section-services__list__item {
      width: 50%;
      padding: 20px; }
      .m-section-services .m-section-services__list__item p {
        text-align: center; }
  .m-section-contact {
    min-height: auto; }
    .m-section-contact .m-section-contact__content,
    .m-section-contact .m-section-contact__info {
      width: 100%;
      height: auto; }
    .m-section-contact .m-section-contact__content {
      height: auto;
      width: 100%;
      padding: 50px 0; }
    .m-section-contact .m-section-contact__content__inner {
      max-width: 90%; }
      .m-section-contact .m-section-contact__content__inner .fa-chevron-left,
      .m-section-contact .m-section-contact__content__inner .fa-chevron-right {
        display: inline-block; }
    .m-section-contact .m-section-contact__content__inner + .fa-chevron-down {
      display: none; } }

@media only screen and (max-width: 575px) {
  .m-section-services .m-section-services__list__item {
    width: 100%; } }

.m-footer {
  padding: 20px 20px 16px;
  background: #fff;
  font-size: 14px;
  text-align: center;
  color: #2c2d32; }
  .m-footer a {
    color: inherit; }
